import React, { Component } from 'react';
import down from './images/down-black.png';
import damklippning from './images/damklippning.jpg';
import herrklippning from './images/herrklippning.jpg';
import skägg from './images/skägg.jpg';
import fest from './images/fest.jpg';
import facial from './images/facial.jpg';
import makeup from './images/makeup.jpg';
import färgning from './images/färgning.jpg';
import mapicon from './images/maps.png';
import microson from './images/microson.png';
import check from './images/check.png';
import instagram from './images/instagram.png';
import accor from './images/accor.jpg';
import ultralifter from './images/ultralifter.jpg';
import triodus from './images/triodus.jpg';
import './style/App.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Table from 'react-bootstrap/Table'
import $ from 'jquery';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import 'firebase/app';

// Initialize Cloud Firestore through Firebase
var maps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8129.605872932398!2d17.919126136313825!3d59.376327685870436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9e6e9530b6f5%3A0x3fe96a96a63f6235!2sKavalleriv%C3%A4gen+26%2C+174+58+Sundbyberg!5e0!3m2!1ssv!2sse!4v1556698307898!5m2!1ssv!2sse";        

function GenerateListItem(service, price, elemFirst, elemLast){
  return(
    <tr>
      <td>{service}</td>
      <td>{elemFirst} {price} {elemLast}</td>
    </tr>
  );
}

function formatDate() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  } 
  if (mm < 10) {
    mm = '0' + mm;
  } 
  var date = dd + '/' + mm + '/' + yyyy; 
  return date;
}

$(window).scroll(function(){
  if($(this).scrollTop() < 300)
    $('#ToTop').hide(300); 
  else 
    $('#ToTop').show(300); 
});

class Home extends Component {
  constructor(props){
    super(props);
    this.MainSection = React.createRef();
    this.Herr = React.createRef();
    this.Dam = React.createRef();
    this.Skönhet = React.createRef();
    this.Home = React.createRef();
    this.About = React.createRef();
    this.Maps = React.createRef();
    this.Contact = React.createRef();
    this.Priser = React.createRef();
    this.Lenses = React.createRef();
  }
  
  scrollToMyRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;
  formatdate = () => formatDate();
  
  render() {
    return (
      
      <div ref={this.MainSection} className="App">
      <Navbar bg="dark" variant="dark" expand="lg" >
        <button className="brandButton nav-title" onClick={this.scrollToMyRef.bind(this, this.MainSection)}>Dudi Beauty</button>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="align-left" >
          <Nav id="nav" className="mr-auto" >
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Skönhet)}>Skönhet</Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Lenses)}>Linser</Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Dam)}>Dam</Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Herr)}>Herr</Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Priser)}>Priser</Nav.Link>
            </Nav>
            <Nav >
              <Nav.Link className="margin-left" href="https://www.instagram.com/dudibeautysalong/" rel="noopener noreferrer" target="_blank"><img className="Footer-icon" src={instagram} alt="footer"></img></Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Maps)}><img src={mapicon} className="glyphicon" alt="maps"></img></Nav.Link>
              <Nav.Link className="anchorButton" onClick={this.scrollToMyRef.bind(this, this.Contact)}>Kontakta oss</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div ref={this.Home} className="Section Home">
            <div className="Shadow-Container" style={{
              borderRadius: '8px'
            }}>
              <h1 className="title">Dudi Beauty</h1>
              <h4 className="title-caption">Frisör & Laserklinik</h4>
              <p>{formatDate()}</p>
              <div className="container">
                <hr></hr>
                <p>
                  Med ett stort utbud för alla och många års erfarenhet har vi skapat en omsorgsfull, lyhörd och kvalitativ
                  arbetsfilosofi baserat på kundens önskemål där du som kund kan känna dig säker i våra händer.
                
                  Vi erbjuder det mesta från enkla till avancerade klippningar, fade, fest- och bruduppsättning,
                  makeup, hudterapeut samt laser hårborttagning.
                </p>

                <p>Du är alltid välkommen in till oss för rådgivning.</p>

                <p>Drop-in och tidsbeställning.</p>
                
              </div>
              
            </div>
            <div className="Show-more" onClick={this.scrollToMyRef.bind(this, this.Skönhet)}>
              <img src={down} className="App-icon vert-move" alt="down" ></img>
            </div>
        </div>

        <div ref={this.Skönhet} className="Section Hud">
        <div className="container">
            <h1 style={{fontSize:'calc(12px + 2vmin)'}}>HUDTERAPI & LASER HÅRBORTTAGNING</h1>
        </div>
        <hr className="line-dark"></hr>
          
          <div className="row-lg-3 center">
          <Row className="justify-content-md-center">
            <Col md="auto">
              <div className="Skin-Container">
                  <img src={accor} className="SkinPreview" alt="logo"></img>
              </div>
            </Col>
              <Col xs lg="4">
              <div className="Offer-Container align-left">
                <h4 style={{color:'#19a7a8'}}>Accor LF+</h4>
                <p>ACCOR är den första lågfrekventa plasmapennan på marknaden för effektiva icke kirurgiska behandlingar.
                  Bevisade kliniska dokumentationer sedan 2013. Tysk design och brittisk
                  tillverkning. Den lågfrekventa vågtekniken av växelström levererar värmen djupare ner i dermis för stimulering av kollagenproduktionen,
                  därav långvariga resultat.</p>
                  <hr></hr>
                  <h5>ACCOR BEHANDLAR/TAR BORT:</h5>
                  <p style={{marginBottom:'4px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Hängande övre och/eller nedre ögonlock</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Lyft av ögonbryn</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Rynkor</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Ärr</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Pigmenteringar</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Fibrom</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Bristningar</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Seborroisk keratos</p>
                  <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Angiom</p>
                  <hr></hr>
                  <p className="danger">Priser kommer snart</p>
              </div>
              </Col>
            </Row> 
            <hr className="line-dark"></hr>
            <Row className="justify-content-md-center">
              <Col md="auto">
                <div className="Skin-Container">
                  <img src={triodus} className="SkinPreview" alt="logo"></img>
                </div>
              </Col>
              <Col xs lg="4">
                <div className="Offer-Container align-left">
                  <h4 style={{color:'#19a7a8'}}>Triodus</h4>
                  <h5>Diodlaser med tre våglängder – behandlar alla hudtyper och hårfärger</h5>
                  <p>Triodus är en kraftfull diodlaser med stor omfång som utför hårborttagning med tre olika våglängder,
                    detta medför säkrare behandlingar och bättre resultat för att kunna behandla fler hud- och hårtyper.</p>
                    <hr></hr>
                    <Table responsive bordered hover variant="light" size="sm" >
                    <thead>
                      <tr>
                        <th>Triodus Behandlingar</th>
                        <th>Pris/Beh (kr)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {GenerateListItem("Överläpp", "350:-", <strike className="danger">650:-</strike>)}
                      {GenerateListItem("Överläpp & Haka", "600:-", <strike className="danger">1200:-</strike>)}
                      {GenerateListItem("Bikinilinjen", "700:-", <strike className="danger">1500:-</strike>)}
                      {GenerateListItem("Underben", "900:-", <strike className="danger">2000:-</strike>)}
                      {GenerateListItem("Hela ben", "1500:-", <strike className="danger">4500:-</strike>)}
                      {GenerateListItem("Rygg", "2000:-", <strike className="danger">4000:-</strike>)}
                      {GenerateListItem("Axill", "800:-", <strike className="danger">1300:-</strike>)}
                      {GenerateListItem("Bröst & Mage", "1500:-", <strike className="danger">4000:-</strike>)}
                      {GenerateListItem("Magsträng", "500:-", <strike className="danger">800:-</strike>)}
                      {GenerateListItem("Armar", "1000:-", <strike className="danger">2000:-</strike>)}
                      {GenerateListItem("Händer", "350:-", <strike className="danger">800:-</strike>)}
                      {GenerateListItem("Enstaka hårstrån", "350:-")}
                    </tbody>
                  </Table>
                </div>
                </Col>
              </Row>

              <hr className="line-dark"></hr>

              <Row className="justify-content-md-center">
              <Col  md="auto">
                <div className="Skin-Container">
                  <img src={ultralifter} className="SkinPreview" alt="logo"></img>
                  </div>
              </Col>
              <Col xs lg="4">
                  <div className="Offer-Container align-left">
                    <h4 style={{color:'#19a7a8'}}>Ultralifter HIFU</h4>
                    <p> Ultralifter HIFU är ett icke-invasivt estetisk behandlingssystem som
                        arbetar med högintensivt fokuserat ultraljud
                        för att stimulera de djupa skikten i huden utan att skada hudytan.</p>

                    <p>Ultralifter HIFU producerar optimal uppvärmning av vävnaden i fokuserade koaguleringspunkter på ett visst djup i huden. 
                    Dessa fokuserade koaguleringspunkter utlöser kroppens egen naturliga läkningsprocess,
                    där det bildas nytt kollagen för att strama upp och lyfta slapp hud.</p>
                    <hr></hr>
                    <Table responsive bordered hover variant="light" size="sm" >
                    <thead>
                      <tr>
                        <th>HIFU Behandlingar</th>
                        <th>Pris/Beh (kr)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {GenerateListItem("Ansikte", "3500:-", <strike className="danger">6000:-</strike>)}
                      {GenerateListItem("Ansikte/Hals", "6000:-", <strike className="danger">10500:-</strike>)}
                      {GenerateListItem("Hals", "2500:-", <strike className="danger">4500:-</strike>)}
                      {GenerateListItem("Armar", "4000:-", <strike className="danger">8000:-</strike>)}
                      {GenerateListItem("Fram/Baksida lår", "4000:-", <strike className="danger">8000:-</strike>)}
                      {GenerateListItem("Ovan knä", "3000:-", <strike className="danger">6000:-</strike>)}
                      {GenerateListItem("Mage", "3000:-", <strike className="danger">8000:-</strike>)}
                      {GenerateListItem("Lokala områden", "1000:-", <span className="Fr-dark">Fr.</span>)}
                    </tbody>
                  </Table>
                </div>
                </Col>
              </Row>
              <hr className="line-dark"></hr>
              <Row className="justify-content-md-center">
              <Col  md="auto">
                <div className="Skin-Container">
                  <img src={microson} className="SkinPreview" alt="logo"></img>
                  </div>
              </Col>
              <Col xs lg="4">
                <div className="Offer-Container align-left">
                      <h4 style={{color:'#19a7a8'}}>Microson HIFU</h4>
                      <p> Microson är ett icke-invasivt estetiskt behandlingssystem
                        som arbetar med högintensivt fokuserat ultraljud för att
                        stimulera de djupa skikten i huden utan att skada hudytan.
                        Microson producerar optimal uppvärmning av vävnaden i
                        fokuserade koaguleringspunkter på ett visst djup i huden.
                        Dessa fokuserade koaguleringspunkter utlöser kroppens
                        egen naturliga läkningsprocess, där det bildas nytt kollagen
                        för att strama upp och lyfta slapp hud.</p>
                        <hr></hr>
                    <h5>MICROSON BEHANDLAR:</h5>
                    <p style={{marginBottom:'4px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Generellt ansiktslyft</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Lyft av ögonbryn</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Hängande kind och dubbelhaka</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Linjer runt läpparna, mungipor</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Rynkor på hals</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Ökad hudelasticitet</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Lokal fettreducering</p>
                    <p style={{marginBottom:'1px', fontSize:'14px'}}><span><img src={check} alt="check" title="check" className="App-icon-sm"></img></span>Huduppstramnning på kroppen</p>
                    <hr></hr>
                    <Table responsive bordered hover variant="light" size="sm" >
                    <thead>
                      <tr>
                        <th>HIFU Behandlingar</th>
                        <th>Pris/Beh (kr)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {GenerateListItem("Ansiktslyft", "4000:-", <strike className="danger">8000:-</strike>)}
                      {GenerateListItem("Ansikte + Hals", "6000:-", <strike className="danger">11000:-</strike>)}
                      {GenerateListItem("Ansikte + Hals + dekolletage", "7000:-", <strike className="danger">14000:-</strike>)}
                      {GenerateListItem("Pannlyft", "2000:-", <strike className="danger">4000:-</strike>)}
                      {GenerateListItem("Ögon", "2000:-", <strike className="danger">3500:-</strike>)}
                      {GenerateListItem("Kindlyft", "2000:-", <strike className="danger">4900:-</strike>)}
                      {GenerateListItem("Hamsterpåsar knä", "2000:-", <strike className="danger">4000:-</strike>)}
                      {GenerateListItem("Dubbelhaka", "2000:-", <strike className="danger">4000:-</strike>)}
                      {GenerateListItem("Hals", "2500:-", <strike className="danger">4900:-</strike>)}
                      {GenerateListItem("Dekolletage", "2500:-", <strike className="danger">6000:-</strike>)}
                    </tbody>
                  </Table>
                </div>
                </Col>
              </Row>
          </div>
        </div>

        <div ref={this.Lenses} className="Section Lenses">
          <div className="container">
              <h1 className="text-big cursive" style={{fontSize:'calc(28px + 3vmin)'}}>Bella's Colored Contact Lenses</h1>
          </div>
          <h4 className="cursive" style={{fontSize:'calc(22px + 2vmin)'}}> - Glow Collection</h4>
          <hr className="line-dark"></hr>
          <div className="row-lg-7 center">
          <Row className="justify-content-md-center">
            <Col xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/luminous_pearl_eye.jpg')} className="Preview" alt="Luminuous Pearl"></img>
                </div>
              <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Luminuous Pearl</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}> Perfekt för en natt i stan när du behöver en fin touch på ditt utseende.</p>
            </Col>

            <Col  xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/husky_gray_green.jpg')} className="Preview" alt="Husky Gray Green"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Husky Gray Green</h4>
                <p style={{marginTop:'0px', fontSize:'14px'}}>Perfekt för personer som vill betona sin egen ögonfärg med något unikt.</p>
            </Col>

            <Col  xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/lime_green.jpg')} className="Preview" alt="Lime Green"></img>
              </div>
              <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Lime Green</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>En av de mer populära färgerna, den skapar värme och glöd inifrån.</p>
            </Col>
          
            <Col  xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/navy_grey.png')} className="Preview" alt="Navy Gray"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Navy Gray</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>Ganska populärt urval, den här färgen mörkar ögonen för en otrolig nyans.</p>
            </Col>
            </Row>

            <Row className="justify-content-md-center">
            <Col  xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/radiant_hazelnut.jpg')} className="Preview" alt="Radiant Hazelnut"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Radiant Hazelnut</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>En uppskattad och förtrollande nyans av ögonfärger.</p>
            </Col>

            <Col xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/vivid_blue.jpg')} className="Preview" alt="Vivid Blue"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Vivid Blue</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>En fantastisk färg som speglar havet.</p>
            </Col>

            <Col xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/radiant_brown.jpg')} className="Preview" alt="Radiant Brown"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Radiant Brown</h4>
                <p style={{marginTop:'0px', fontSize:'14px'}}>En fin och mystisk nyans som kompletterar alla hudtoner.</p>
            </Col>

            <Col xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/radiant_gray.jpg')} className="Preview" alt="Radiant Gray"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Radiant Gray</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>En färg som fånga alla blickar.</p>
            </Col>

            <Col xs lg="2">
              <div className="Skin-Container">
                <img src={require('./images/bellalenses/caramel_gray.jpg')} className="Preview" alt="Gray Caram"></img>
                </div>
                <h4 style={{marginBottom:'0px', marginTop: '5px', fontSize:'calc(12px + 2vmin)'}}>Gray Caram</h4>
              <p style={{marginTop:'0px', fontSize:'14px'}}>En underbar nyans som speglar himlen.</p>
            </Col>
          </Row>
        </div>
        </div>

        <div ref={this.Dam} className="Section Dam">
        <div className="container">
            <h1 style={{fontSize:'calc(12px + 2vmin)'}}>DAM</h1>
        </div>
        <hr className="line"></hr>
          <Row>
            <Col>
                  <img src={fest} className="Offer" alt="logo"></img>
                  <span className="caption-sm">Fest- & bruduppsättning</span>
            </Col>
            <Col>
                  <img src={makeup} className="Offer" alt="logo"></img>
                  <span className="caption-sm">Makeup</span>
            </Col>
            <Col>
                  <img src={damklippning} className="Offer" alt="logo"></img>
                  <span className="caption-sm">Klippning</span>
            </Col>
            <Col>
                  <img src={färgning} className="Offer"  alt="logo"></img>
                  <span className="caption-sm">Färgning</span>
            </Col>
            <Col>
                  <img src={facial} className="Offer"  alt="logo"></img>
                  <span className="caption-sm">Ansiktsbehandling</span>
            </Col>
          </Row>
        </div>

        <div ref={this.Herr} className="Section Herr">
        <div className="container">
            <h1 style={{fontSize:'calc(12px + 2vmin)'}}>HERR</h1>
        </div>
        <hr className="line"></hr>
          <Row>
              <Col>
                <img src={herrklippning} className="Offer" alt="logo"></img>
                <span className="caption-sm">Klippning / Fade</span>
              </Col>
              <Col>
                <img src={skägg} className="Offer" alt="logo"></img>
                <span className="caption-sm">Skägg</span>
              </Col>
          </Row>
        </div>

        <div ref={this.Priser} className="Section Priser">
        <h1 style={{fontSize:'calc(12px + 2vmin)'}}>PRISLISTA</h1>
        <hr className="line-dark"></hr>
        <Table className="Shadow" bordered hover variant="dark" size="sm" >
          <thead>
            <tr>
              <th>Klippning, Styling & Hårborttagning</th>
              <th>Pris (kr)</th>
            </tr>
          </thead>
          <tbody>
            {GenerateListItem("Herrklippning", "170:-")}
            {GenerateListItem("Skägg", "150:-")}
            {GenerateListItem("Klippning & skägg", "280:-")}
            {GenerateListItem("Barnklippning (till 12 år)", "140:-")}
            {GenerateListItem("Tvätt & fön", "180:-")}
            {GenerateListItem("Klipp & Fön", "330:-")}
            {GenerateListItem("Damklippning", "200:-")}
            {GenerateListItem("Färg", "320:-", <span className="Fr">Fr.</span>)}
            {GenerateListItem("Slingor",  "700:-", <span className="Fr">Fr.</span>)}
            {GenerateListItem("Öronhåltagare", "100:-", null, <span className="Fr">St</span>)}
            {GenerateListItem("Uppsättning", "500:-")}
            {GenerateListItem("Make up", "500:-", <span className="Fr">Fr.</span>)}
            {GenerateListItem("Permanent hårborttagning", "200:-", <span className="Fr">Fr.</span>)}
            {GenerateListItem("Ansiktsbehandling med syneri", "600:-")}
          </tbody>
        </Table>
      </div>
        
       
       <div ref={this.Maps} className="Section Contact">
         <div className="container">
          <div id="instafeed" className="row"></div>
         </div>
      
        <div className="google-maps">
          <iframe title="maps" src={maps} width="100%" height="400" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
        </div>
        
        <div className="contact-container">
          <div className="contact-box align-left">
            <h2>Kontakta oss</h2>
            <p>&bull; Tel: 08 - 733 05 33</p>
            <p>&bull; Plats: Kavallerivägen 26, 174 58 Sundbyberg</p>
            <hr className="line"></hr>
            <h2>Öppettider</h2>
                <tbody>
                  <tr>
                    <td>&bull; Måndag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Tisdag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Onsdag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Torsdag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Fredag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Lördag </td>
                    <td>: 10.00 - 19.00</td>
                  </tr>
                  <tr>
                    <td>&bull; Söndag </td>
                    <td>: <span className="danger">Stängt</span></td>
                  </tr>
                </tbody>
          </div>
          </div>
          
        </div>

        <div ref={this.Contact} className="Footer">
          <div>
            <button className="footerButton" onClick={this.scrollToMyRef.bind(this, this.Skönhet)}>Skönhet</button> |
            <button className="footerButton" onClick={this.scrollToMyRef.bind(this, this.Lenses)}>Linser</button>  |
            <button className="footerButton" onClick={this.scrollToMyRef.bind(this, this.Dam)}>Dam</button> |
            <button className="footerButton" onClick={this.scrollToMyRef.bind(this, this.Herr)}>Herr</button> |
            <button className="footerButton" onClick={this.scrollToMyRef.bind(this, this.Priser)}>Priser</button> 
          </div>
        </div>
        <div ref={this.Contact} className="Footer">
          <p>Copyright &copy; 2019 Dudi Beauty AB, 556875-7859</p>
        </div>

        <div id="ToTop" className="contToTop" onClick={this.scrollToMyRef.bind(this, this.MainSection)}>
          <div className="scrollToTop">
            <div className="arrow-up"></div>
          </div>
        </div>
      </div>
    );
  }
}


export default Home;
//<iframe title="widget" src="//lightwidget.com/widgets/379a30b69d8d566bac23d41539fa7997.html" scrolling="no" allowtransparency="true" className="lightwidget-widget instagram-widget"></iframe>
